import React from "react";
import AnalyticsIcon from "components/Icons/AnalyticsIcon";
import FolderIcon from "components/Icons/FolderIcon";
import InfoIcon from "components/Icons/InfoIcon";
import UserIcon from "components/Icons/UserIcon";
import ListIcon from "components/Icons/ListIcon";
import FlagIcon from "components/Icons/FlagIcon";
import CalendarIcon from "components/Icons/CalendarIcon";
import MaterialIcon from "components/Icons/MaterialIcon";
import SignOutIcon from "components/Icons/SignOutIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import RecommendIcon from "@material-ui/icons/OfflinePinOutlined";
import FeedbackIcon from "@material-ui/icons/FeedbackOutlined";

import {
  PERMISSIONS,
  ALL_PERMISSIONS,
  USERMANAGEMENT_PERMISSIONS,
  TRAININGMATERIALS_PERMISSIONS,
  CONTENTMANAGEMENT_PERMISSIONS,
  NOTIFICATION_PERMISSIONS,
  SETTINGS_PERMISSIONS,
} from "constants/types";
import { isVisible } from "utils/permissions";

const PAGES = {
  HOME: "/",
  PROFILE: "/profile",
  SIGN_IN: "/auth/sign-in",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password/:token",

  PATHS: "/paths",
  DASHBOARD: "/dashboard",
  ANALYTICS: "/analytics",
  USERS: "/users",
  ROLES: "/roles",
  USER_SUBSCRIPTIONS: "/user-subscriptions",
  DEFAULT_SUBSCRIPTIONS: "/default-subscriptions",
  ZOOM_ACCOUNTS: "/zoom-accounts",
  EDUCATORS: "/educators",
  CATEGORIES: "/categories",
  GROUP_CATEGORIES: "/categories-group",
  EVENTS: "/events",
  EVENTS_LIVE: "/events-live",
  SCHEDULES: "/schedules",
  PLAYLISTS: "/playlists",
  ACADEMY_PLAYLISTS: "/academy-playlists",
  RESOURCES: "/resources",
  EDUCATOR_RESOURCES: "/educator-resources",
  EDUCATOR_RESOURCES_VIEW: "/view-resources",
  FAQS: "/faqs",
  BLACKLIST: "/blacklist",
  EVENT_FAQS: "/event-faqs",
  TAGS: "/tags",
  VIDEOS: "/videos",
  NOTIFICATIONS: "/notifications",
  RESOURCE_PAGE: "/resource-page",
  ONBOARDING_CONTENT: "/onboarding-content",
  RECOMMENDATIONS: "/recommendations",
  RECOMMENDATIONS_SETTINGS: "/recommendations/settings",
  STREAM_NOTIFICATIONS: "/stream-notifications",
  AUTORESPONSE_EMAILS: "/auto-response-emails",
  SIGN_OUT: "/auth/sign-out",
  ADMIN_ACTIONS_LOG: "/admin-actions-log",
  LANGUAGES: "/languages",
  DELAYED_JOBS: "/delayed-jobs",
  TIMEZONES: "/timezones",
  POLICY_EDITOR: "/policy",
  STREAMING_SERVICES: "/streaming-services",
  MAINTENANCE_SETTINGS: "/maintenance-settings",
  QUESTION_MANAGEMENT: "/question-management",
  FEEDBACK_ANALYTICS: "/feedback-analytics",
};

const permissionLinkMatchList = {
  0: {
    link: PAGES.USERS,
    permissions: [PERMISSIONS.VIEW_USERS, PERMISSIONS.MANAGE_ALL_USERS],
  },
  1: {
    link: PAGES.EDUCATORS,
    permissions: [PERMISSIONS.MANAGE_ALL_EDUCATORS],
  },
  2: {
    link: PAGES.ROLES,
    permissions: [PERMISSIONS.VIEW_ROLES, PERMISSIONS.MANAGE_ALL_ROLES],
  },
  3: {
    link: PAGES.EDUCATOR_RESOURCES,
    permissions: [PERMISSIONS.MANAGE_ALL_EDUCATOR_RESOURCES],
  },
  4: {
    link: PAGES.EDUCATOR_RESOURCES_VIEW,
    permissions: [PERMISSIONS.VIEW_EDUCATOR_RESOURCES, PERMISSIONS.MANAGE_ALL_EDUCATOR_RESOURCES],
  },
  5: {
    link: PAGES.FAQS,
    permissions: [PERMISSIONS.MANAGE_ALL_FAQS],
  },
  6: {
    link: PAGES.CATEGORIES,
    permissions: [PERMISSIONS.MANAGE_ALL_CATEGORIES],
  },
  7: {
    link: PAGES.EVENTS,
    permissions: [PERMISSIONS.EDIT_EVENTS, PERMISSIONS.MANAGE_ALL_EVENTS],
  },
  8: {
    link: PAGES.SCHEDULES,
    permissions: [PERMISSIONS.EDIT_SCHEDULES, PERMISSIONS.MANAGE_ALL_SCHEDULES],
  },
  9: {
    link: PAGES.RESOURCES,
    permissions: [PERMISSIONS.EDIT_RESOURCES, PERMISSIONS.MANAGE_ALL_RESOURCES],
  },
  10: {
    link: PAGES.PLAYLISTS,
    permissions: [PERMISSIONS.EDIT_PLAYLISTS, PERMISSIONS.MANAGE_ALL_PLAYLISTS],
  },
  11: {
    link: PAGES.VIDEOS,
    permissions: [PERMISSIONS.EDIT_VIDEOS, PERMISSIONS.MANAGE_ALL_VIDEOS],
  },
  12: {
    link: PAGES.NOTIFICATIONS,
    permissions: [PERMISSIONS.MANAGE_APP_NOTIFICATIONS],
  },
  13: {
    link: PAGES.PROFILE,
    permissions: ALL_PERMISSIONS,
  },
  14: {
    link: PAGES.USER_SUBSCRIPTIONS,
    permissions: [PERMISSIONS.VIEW_ROLES, PERMISSIONS.MANAGE_ALL_ROLES],
  },
  15: {
    link: PAGES.ZOOM_ACCOUNTS,
    permissions: [PERMISSIONS.VIEW_ROLES, PERMISSIONS.MANAGE_ALL_ROLES],
  },
  16: {
    link: PAGES.EVENT_FAQS,
    permissions: [PERMISSIONS.MANAGE_ALL_EVENTS, PERMISSIONS.EDIT_EVENTS],
  },
  17: {
    link: PAGES.ACADEMY_PLAYLISTS,
    permissions: [PERMISSIONS.EDIT_PLAYLISTS, PERMISSIONS.MANAGE_ALL_PLAYLISTS],
  },
  18: {
    link: PAGES.DEFAULT_SUBSCRIPTIONS,
    permissions: [PERMISSIONS.VIEW_ROLES, PERMISSIONS.MANAGE_ALL_ROLES],
  },
  19: {
    link: PAGES.TAGS,
    permissions: [PERMISSIONS.MANAGE_ALL_TAGS],
  },
  20: {
    link: PAGES.STREAM_NOTIFICATIONS,
    permissions: [PERMISSIONS.EDIT_VIDEOS, PERMISSIONS.MANAGE_ALL_VIDEOS],
  },
  21: {
    link: PAGES.ANALYTICS,
    permissions: [PERMISSIONS.VIEW_ANALYTICS],
  },
  27: {
    link: PAGES.PATHS,
    permissions: ALL_PERMISSIONS,
  },
  28: {
    link: PAGES.EVENTS_LIVE,
    permissions: [PERMISSIONS.BE_SUPERADMIN],
  },
  29: {
    link: PAGES.RESOURCE_PAGE,
    permissions: ALL_PERMISSIONS,
  },
  30: {
    link: PAGES.ONBOARDING_CONTENT,
    permissions: [PERMISSIONS.BE_SUPERADMIN],
  },
  31: {
    link: PAGES.RECOMMENDATIONS,
    permissions: [PERMISSIONS.BE_SUPERADMIN],
  },
  32: {
    link: PAGES.RECOMMENDATIONS_SETTINGS,
    permissions: [PERMISSIONS.BE_SUPERADMIN],
  },
  33: {
    link: PAGES.QUESTION_MANAGEMENT,
    permissions: [PERMISSIONS.BE_SUPERADMIN],
  },
  34: {
    link: `${PAGES.FEEDBACK_ANALYTICS}`,
    permissions: [PERMISSIONS.BE_SUPERADMIN, PERMISSIONS.VIEW_FEEDBACK_ANALYTICS],
  },
};

const sidebarItems = [
  {
    title: "Feedbacks",
    icon: <FeedbackIcon style={{ marginLeft: -2, width: 20 }} />,
    permissions: [PERMISSIONS.VIEW_FEEDBACK_ANALYTICS],
    subItems: [
      {
        subTitle: "Feedback Analytics",
        ...permissionLinkMatchList[34],
        link: `${PAGES.FEEDBACK_ANALYTICS}/sessions`,
      },
      {
        subTitle: "Questions Management",
        ...permissionLinkMatchList[33],
      },
    ],
  },
  // {
  //   title: "Dashboard",
  //   icon: <DashboardIcon />,
  //   subItems: [],
  //   link: PAGES.DASHBOARD,
  //   permissions: ALL_PERMISSIONS,
  // },
  {
    title: "Admin actions log",
    link: PAGES.ADMIN_ACTIONS_LOG,
    icon: <FolderIcon />,
    subItems: [],
    permissions: [PERMISSIONS.VIEW_ADMIN_ACTION_LOG],
  },
  {
    title: "Analytics",
    icon: <AnalyticsIcon />,
    subItems: [],
    ...permissionLinkMatchList[21],
  },
  {
    title: "User Management",
    icon: <UserIcon />,
    permissions: USERMANAGEMENT_PERMISSIONS,
    subItems: [
      {
        subTitle: "Users",
        ...permissionLinkMatchList[0],
      },
      {
        subTitle: "Educators",
        ...permissionLinkMatchList[1],
      },
      {
        subTitle: "Roles",
        ...permissionLinkMatchList[2],
      },
    ],
  },
  {
    title: "Content Management",
    icon: <FolderIcon />,
    permissions: CONTENTMANAGEMENT_PERMISSIONS,
    subItems: [
      {
        subTitle: "Resource Center (Admin)",
        ...permissionLinkMatchList[3],
      },
      {
        subTitle: "Resource Center",
        ...permissionLinkMatchList[4],
      },
      {
        subTitle: "FAQ",
        ...permissionLinkMatchList[5],
      },
      {
        subTitle: "Event FAQ",
        ...permissionLinkMatchList[16],
      },
      {
        subTitle: "Resource Page",
        ...permissionLinkMatchList[29],
      },
      {
        subTitle: "Onboarding Q&A",
        ...permissionLinkMatchList[30],
      },
    ],
  },
  {
    title: "Training Materials",
    icon: <MaterialIcon />,
    permissions: TRAININGMATERIALS_PERMISSIONS,
    subItems: [
      {
        subTitle: "Student Resources",
        ...permissionLinkMatchList[9],
      },
      {
        subTitle: "Student Playlists",
        ...permissionLinkMatchList[10],
      },
      {
        subTitle: "Academy Playlists",
        ...permissionLinkMatchList[17],
      },
      {
        subTitle: "Video Management",
        ...permissionLinkMatchList[11],
      },
    ],
  },
  {
    title: "Categories",
    icon: <ListIcon />,
    ...permissionLinkMatchList[6],
    subItems: [],
  },
  {
    title: "Education Paths",
    icon: <MaterialIcon />,
    permissions: ALL_PERMISSIONS,
    subItems: [
      {
        subTitle: "Path Management",
        ...permissionLinkMatchList[27],
      },
    ],
  },
  {
    title: "Events",
    icon: <FlagIcon />,
    permissions: [PERMISSIONS.EDIT_EVENTS, PERMISSIONS.MANAGE_ALL_EVENTS, PERMISSIONS.VIEW_ROLES, PERMISSIONS.MANAGE_ALL_ROLES],
    subItems: [
      {
        subTitle: "Live Event",
        ...permissionLinkMatchList[7],
      },
      {
        subTitle: "Zoom Event",
        ...permissionLinkMatchList[15],
      },
      {
        subTitle: "Stop Events",
        ...permissionLinkMatchList[28],
      },
    ],
  },
  {
    title: "Recommendations",
    icon: <RecommendIcon style={{ marginLeft: -2, width: 20 }} />,
    permissions: USERMANAGEMENT_PERMISSIONS,
    subItems: [
      {
        subTitle: "Recommendations management",
        ...permissionLinkMatchList[31],
      },
      {
        subTitle: "Settings",
        ...permissionLinkMatchList[32],
      },
    ],
  },
  {
    title: "Schedules",
    icon: <CalendarIcon color="#fff" />,
    ...permissionLinkMatchList[8],
    subItems: [],
  },
  {
    title: "Notifications",
    icon: <InfoIcon />,
    permissions: NOTIFICATION_PERMISSIONS,
    subItems: [
      {
        subTitle: "App Notification",
        ...permissionLinkMatchList[12],
      },
      // {
      //   subTitle: 'Stream Notification',
      //   ...permissionLinkMatchList[20]
      // },
    ],
  },
  {
    title: "Settings",
    icon: <SettingsIcon />,
    permissions: SETTINGS_PERMISSIONS,
    subItems: [
      {
        subTitle: "Languages",
        link: PAGES.LANGUAGES,
        permissions: [PERMISSIONS.MANAGE_LANGUAGES],
      },
      {
        subTitle: "Policy Editor",
        permissions: [PERMISSIONS.MANAGE_POLICY],
        link: PAGES.POLICY_EDITOR,
      },
      {
        subTitle: "Timezones",
        link: PAGES.TIMEZONES,
        permissions: [PERMISSIONS.MANAGE_TIMEZONES],
      },
      {
        subTitle: "Subscriptions",
        ...permissionLinkMatchList[14],
      },
      {
        subTitle: "Default Subscriptions",
        ...permissionLinkMatchList[18],
      },
      {
        subTitle: "Tag",
        ...permissionLinkMatchList[19],
      },
      {
        subTitle: "Delayed Jobs",
        link: PAGES.DELAYED_JOBS,
        permissions: [PERMISSIONS.BE_SUPERADMIN],
      },
      {
        subTitle: "Streaming Services",
        link: PAGES.STREAMING_SERVICES,
        permissions: [PERMISSIONS.BE_SUPERADMIN],
      },
      {
        subTitle: "Maintenance Settings",
        link: PAGES.MAINTENANCE_SETTINGS,
        permissions: [PERMISSIONS.BE_SUPERADMIN],
      },
    ],
  },
  {
    title: "Profile",
    subItems: [],
    ...permissionLinkMatchList[13],
    hide: true,
  },
  {
    title: "Sign Out",
    icon: <SignOutIcon color="#A20F3C" />,
    subItems: [],
    link: PAGES.SIGN_IN,
    permissions: ALL_PERMISSIONS,
  },
];

const getPageTitle = (link, search) => {
  let title = "";
  let path = "/";
  if (link === PAGES.VIDEOS && search && search.includes("?eventId")) {
    return { title: `Livestream / Events / Videos`, path: PAGES.EVENTS };
  }
  if (link.includes(PAGES.FEEDBACK_ANALYTICS)) {
    let path = PAGES.FEEDBACK_ANALYTICS;

    if (link.includes("educators")) {
      path += "/educators";
    } else if (link.includes("sessions")) {
      path += "/sessions";
    }

    return { title: `Feedbacks / Analytics`, path };
  }
  for (const item of sidebarItems) {
    if (link && link.includes(item.link)) {
      title = item.title;
      path = item.link;
      break;
    }
    if (item.subItems) {
      for (const subIt of item.subItems) {
        if (subIt && link && link.includes(subIt.link)) {
          title = `${item.title} / ${subIt.subTitle}`;
          path = subIt.link;
          break;
        }
      }
    }
  }
  return { title, path };
};

const getLandingLink = (permissions) => {
  for (const linkInfo of Object.values(permissionLinkMatchList)) {
    const requiredPermissions = linkInfo.permissions;
    if (isVisible(permissions, requiredPermissions)) {
      return linkInfo.link;
    }
  }
  return "/";
};

const isProduction = window.location.host.includes("admin.wowpowers.com");

const getStreamPlayerURL = ({ educatorName, eventId, videoId }) => {
  if (!educatorName) {
    educatorName = "unknown_name";
  }
  educatorName = educatorName.toLowerCase().replace(/ /g, "_");

  const baseUrl = !isProduction ? "https://stg.wowpowers.com" : "https://wowpowers.com";
  const streamPlayerVideoUrl = `${baseUrl}/stream-player/[educatorName]/livestream/[eventId]/video/[videoId]`;
  const streamPlayerLiveStreamUrl = `${baseUrl}/stream-player/[educatorName]/livestream/[eventId]`;

  if (!!videoId) {
    return streamPlayerVideoUrl.replace("[educatorName]", educatorName).replace("[eventId]", eventId).replace("[videoId]", videoId);
  } else {
    return streamPlayerLiveStreamUrl.replace("[educatorName]", educatorName).replace("[eventId]", eventId);
  }
};

export { PAGES, permissionLinkMatchList, sidebarItems, getPageTitle, getLandingLink, getStreamPlayerURL };
