const CATEGORIES = {
  CATEGORY_CHANNEL: "CATEGORY_CHANNEL",
  CATEGORY_COURSE: "CATEGORY_COURSE",
  CATEGORY_GROUP: "CATEGORY_GROUP",
};

const CATEGORY_TYPES = [
  // 'CATEGORY_TOOL',
  // 'CATEGORY_PRODUCT',
  CATEGORIES.CATEGORY_CHANNEL,
  CATEGORIES.CATEGORY_COURSE,
  CATEGORIES.CATEGORY_GROUP,
];

const DURATION_FILTER_TYPES = ["is between"];

const TRIMMED_FILTER_TYPES = ["raw", "trimmed"];

const PERMISSIONS = {
  VIEW_USERS: "VIEW_USERS",
  MANAGE_ALL_USERS: "MANAGE_ALL_USERS",
  VIEW_ROLES: "VIEW_ROLES",
  MANAGE_ALL_ROLES: "MANAGE_ALL_ROLES",
  MANAGE_ALL_CATEGORIES: "MANAGE_ALL_CATEGORIES",
  MANAGE_ALL_EDUCATORS: "MANAGE_ALL_EDUCATORS",
  EDIT_EVENTS: "EDIT_EVENTS",
  MANAGE_ALL_EVENTS: "MANAGE_ALL_EVENTS",
  EDIT_RESOURCES: "EDIT_RESOURCES",
  MANAGE_ALL_RESOURCES: "MANAGE_ALL_RESOURCES",
  EDIT_PATHS: "EDIT_PATHS",
  MANAGE_ALL_PATHS: "MANAGE_ALL_PATHS",
  EDIT_SCHEDULES: "EDIT_SCHEDULES",
  MANAGE_ALL_SCHEDULES: "MANAGE_ALL_SCHEDULES",
  EDIT_VIDEOS: "EDIT_VIDEOS",
  MANAGE_ALL_VIDEOS: "MANAGE_ALL_VIDEOS",
  EDIT_PLAYLISTS: "EDIT_PLAYLISTS",
  MANAGE_ALL_PLAYLISTS: "MANAGE_ALL_PLAYLISTS",
  VIEW_EDUCATOR_RESOURCES: "VIEW_EDUCATOR_RESOURCES",
  MANAGE_ALL_FAQS: "MANAGE_ALL_FAQS",
  MANAGE_ALL_EDUCATOR_RESOURCES: "MANAGE_ALL_EDUCATOR_RESOURCES",
  MANAGE_APP_NOTIFICATIONS: "MANAGE_APP_NOTIFICATIONS",
  MANAGE_AUTO_RESPONSE_EMAILS: "MANAGE_AUTO_RESPONSE_EMAILS",
  VIEW_PREMIUM_EVENTS: "VIEW_PREMIUM_EVENTS",
  MANAGE_ALL_BLOCK_CHATS: "MANAGE_ALL_BLOCK_CHATS",
  EDIT_BLACKLIST: "EDIT_BLACKLIST",
  MANAGE_ALL_TAGS: "MANAGE_ALL_TAGS",
  EDIT_VIEWS_EST: "EDIT_VIEWS_EST",
  MANAGE_COMMENT_HISTORY: "MANAGE_COMMENT_HISTORY",
  VIEW_ANALYTICS: "VIEW_ANALYTICS",
  MANAGE_LANGUAGES: "MANAGE_LANGUAGES",
  VIEW_ADMIN_ACTION_LOG: "VIEW_ADMIN_ACTION_LOG",
  LOGIN_AS_USER: "LOGIN_AS_USER",
  MANAGE_TIMEZONES: "MANAGE_TIMEZONES",
  MANAGE_POLICY: "MANAGE_POLICY",
  VIEW_FEEDBACK_ANALYTICS: "VIEW_FEEDBACK_ANALYTICS",
  BE_SUPERADMIN: "BE_SUPERADMIN",
};

const PERMISSION_TYPES = [
  { label: "Able to view users", value: PERMISSIONS.VIEW_USERS },
  { label: "Able to manage all users", value: PERMISSIONS.MANAGE_ALL_USERS },
  { label: "Able to view roles", value: PERMISSIONS.VIEW_ROLES },
  { label: "Able to manage all roles", value: PERMISSIONS.MANAGE_ALL_ROLES },
  { label: "Able to manage all categories", value: PERMISSIONS.MANAGE_ALL_CATEGORIES },
  { label: "Able to manage all educators", value: PERMISSIONS.MANAGE_ALL_EDUCATORS },
  { label: "Able to edit event", value: PERMISSIONS.EDIT_EVENTS },
  { label: "Able to manage all events", value: PERMISSIONS.MANAGE_ALL_EVENTS },
  { label: "Able to edit recource", value: PERMISSIONS.EDIT_RESOURCES },
  { label: "Able to manage all recources", value: PERMISSIONS.MANAGE_ALL_RESOURCES },
  { label: "Able to view all educator recources", value: PERMISSIONS.VIEW_EDUCATOR_RESOURCES },
  { label: "Able to manage all educator recources", value: PERMISSIONS.MANAGE_ALL_EDUCATOR_RESOURCES },
  { label: "Able to edit schedule", value: PERMISSIONS.EDIT_SCHEDULES },
  { label: "Able to manage all schedules", value: PERMISSIONS.MANAGE_ALL_SCHEDULES },
  { label: "Able to edit video", value: PERMISSIONS.EDIT_VIDEOS },
  { label: "Able to manage all videos", value: PERMISSIONS.MANAGE_ALL_VIDEOS },
  { label: "Able to edit playlist", value: PERMISSIONS.EDIT_PLAYLISTS },
  { label: "Able to manage all playlists", value: PERMISSIONS.MANAGE_ALL_PLAYLISTS },
  { label: "Able to manage app notifications", value: PERMISSIONS.MANAGE_APP_NOTIFICATIONS },
  { label: "Able to manage auto response emails", value: PERMISSIONS.MANAGE_AUTO_RESPONSE_EMAILS },
  { label: "Able to manage all FAQ", value: PERMISSIONS.MANAGE_ALL_FAQS },
  { label: "Able to manage all Tags", value: PERMISSIONS.MANAGE_ALL_TAGS },
  { label: "Able to view premium events", value: PERMISSIONS.VIEW_PREMIUM_EVENTS },
  { label: "Able to manage all block chats", value: PERMISSIONS.MANAGE_ALL_BLOCK_CHATS },
  { label: "Able to edit blacklist", value: PERMISSIONS.EDIT_BLACKLIST },
  { label: "Able to manage comments history", value: PERMISSIONS.MANAGE_COMMENT_HISTORY },
  { label: "Able to view analytics", value: PERMISSIONS.VIEW_ANALYTICS },
  { label: "Able to manage languages", value: PERMISSIONS.MANAGE_LANGUAGES },
  { label: "Able to view admin action log", value: PERMISSIONS.VIEW_ADMIN_ACTION_LOG },
  { label: "Able to login as user", value: PERMISSIONS.LOGIN_AS_USER },
  { label: "Able to manage timezones", value: PERMISSIONS.MANAGE_TIMEZONES },
  { label: "Able to manage policy", value: PERMISSIONS.MANAGE_POLICY },
  { label: "Able to edit paths", value: PERMISSIONS.EDIT_PATHS },
  { label: "Able to manage all paths", value: PERMISSIONS.MANAGE_ALL_PATHS },
  { label: "Able to view feedback analytics", value: PERMISSIONS.VIEW_FEEDBACK_ANALYTICS },
];

const ALL_PERMISSIONS = PERMISSION_TYPES.map((item) => item.value);
const USERMANAGEMENT_PERMISSIONS = [PERMISSIONS.VIEW_USERS, PERMISSIONS.MANAGE_ALL_USERS, PERMISSIONS.VIEW_ROLES, PERMISSIONS.MANAGE_ALL_ROLES, PERMISSIONS.MANAGE_ALL_EDUCATORS];
const LIVESTREAM_PERMISSIONS = [
  PERMISSIONS.MANAGE_ALL_CATEGORIES,
  PERMISSIONS.EDIT_EVENTS,
  PERMISSIONS.MANAGE_ALL_EVENTS,
  PERMISSIONS.EDIT_SCHEDULES,
  PERMISSIONS.MANAGE_ALL_SCHEDULES,
];

const CONTENTMANAGEMENT_PERMISSIONS = [
  PERMISSIONS.VIEW_EDUCATOR_RESOURCES,
  PERMISSIONS.MANAGE_ALL_EDUCATOR_RESOURCES,
  PERMISSIONS.MANAGE_ALL_FAQS,
  PERMISSIONS.MANAGE_ALL_EVENTS,
  PERMISSIONS.EDIT_EVENTS,
  PERMISSIONS.MANAGE_ALL_TAGS,
  PERMISSIONS.EDIT_BLACKLIST,
];

const TRAININGMATERIALS_PERMISSIONS = [
  PERMISSIONS.EDIT_RESOURCES,
  PERMISSIONS.MANAGE_ALL_RESOURCES,
  PERMISSIONS.EDIT_VIDEOS,
  PERMISSIONS.MANAGE_ALL_VIDEOS,
  PERMISSIONS.EDIT_PLAYLISTS,
  PERMISSIONS.MANAGE_ALL_PLAYLISTS,
];

const NOTIFICATION_PERMISSIONS = [PERMISSIONS.MANAGE_APP_NOTIFICATIONS, PERMISSIONS.MANAGE_AUTO_RESPONSE_EMAILS, PERMISSIONS.MANAGE_ALL_VIDEOS, PERMISSIONS.EDIT_VIDEOS];

const SETTINGS_PERMISSIONS = [PERMISSIONS.MANAGE_POLICY, PERMISSIONS.MANAGE_TIMEZONES, PERMISSIONS.MANAGE_LANGUAGES];

const ROLE_TYPES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  POWER_USER: "POWER_USER",
  EDUCATOR_USER: "EDUCATOR_USER",
  MELIUS_USER: "MELIUS_USER",
};

const USER_TYPES = [
  { label: "Super Admin", role: ROLE_TYPES.SUPER_ADMIN },
  { label: "Educator", role: ROLE_TYPES.EDUCATOR_USER },
  { label: "Path Manager", role: ROLE_TYPES.EDUCATOR_USER },
  { label: "Power User", role: ROLE_TYPES.POWER_USER },
  { label: "BE User", role: ROLE_TYPES.MELIUS_USER },
];

const USER_STATUS_TYPE = ["ACTIVE", "INACTIVE"];

const ACADEMY_TYPES = ["COURSE", "COMMUNITY"];

const PAGES = {
  HOME: "HOME",
  LIVE_STREAMS: "LIVE_STREAMS",
  EDUCATORS: "EDUCATORS",
  CATEGORIES: "CATEGORIES",
  SCHEDULE: "SCHEDULE",
  STREAM_PLAYER: "STREAM_PLAYER",
  ACADEMY: "ACADEMY",
};

const PAGE_TYPES = Object.keys(PAGES).map((item) => ({ label: item }));

const INNER_NOTIFICATIONS = {
  INFO: "INFO",
  ERROR: "ERROR",
  ALERT: "ALERT",
};

const NOTIFICATION_TYPES = Object.keys(INNER_NOTIFICATIONS).map((item) => ({ label: item }));

const EDUCATOR_RESOURCE_TYPES = {
  VIDEO: "VIDEO",
  FILE: "FILE",
};

const VIDEO_STATUS = {
  ACTIVE: "ACTIVE",
  WAIT: "WAIT",
  WAIT_FOR_VIDEO_FILE: "WAIT_FOR_VIDEO_FILE",
  UPLOAD: "UPLOAD",
  FAIL: "FAIL",
  SKIPPED: "SKIPPED",
};

const PATH_VIDEO_UPLOAD_STATUS = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const RESOURCE_TYPES = {
  FILE: "FILE",
  LINK: "LINK",
  VIDEO: "VIDEO",
};

const SCHEDULE_TYPE = {
  NO_REPEAT: "NO_REPEAT",
  CUSTOM: "CUSTOM",
  DAILY: "DAILY",
  WEEKDAYS: "WEEKDAYS",
};

const KNOWLEDGE_LEVEL_TYPE = [
  { type: 1, label: "Beginner" },
  { type: 3, label: "Advanced" },
];

const SCHEDULE_TYPES = [
  { scheduleType: SCHEDULE_TYPE.NO_REPEAT, label: "Does not repeat" },
  { scheduleType: SCHEDULE_TYPE.DAILY, label: "Daily" },
  { scheduleType: SCHEDULE_TYPE.WEEKDAYS, label: "Weekdays (Mon-Fri)" },
  { scheduleType: SCHEDULE_TYPE.CUSTOM, label: "Custom" },
];

const DEFAULT_SUBSCRIPTION_TYPES = ["EVENT", "COURSE", "COMMUNITY"];

const PUBLISH_TYPES = [
  { _id: false, label: "Unpublish" },
  { _id: true, label: "Publish" },
];

const EXPIRED_TYPES = [
  { type: "HOUR_1", label: "1 Hour", hours: 1 },
  { type: "HOUR_2", label: "2 Hours", hours: 2 },
  { type: "HOUR_4", label: "4 Hours", hours: 4 },
  { type: "DAY_1", label: "1 Day", hours: 24 },
];

const expiredTypeMap = {
  HOUR_1: EXPIRED_TYPES[0].label,
  HOUR_2: EXPIRED_TYPES[1].label,
  HOUR_4: EXPIRED_TYPES[2].label,
  DAY_1: EXPIRED_TYPES[3].label,
};

const ANALYTICS_SORT_TYPES = [
  { label: "DATE", type: "DATE" },
  { label: "TOTAL VIEWS", type: "VIEWS" },
  { label: "TIME", type: "TIME" },
  { label: "RETENTION", type: "RETENTION" },
  { label: "LIVE VIEWS", type: "MAX_LIVE_VIEWS" },
];

export {
  USER_TYPES,
  CATEGORIES,
  CATEGORY_TYPES,
  PERMISSIONS,
  PERMISSION_TYPES,
  ROLE_TYPES,
  USER_STATUS_TYPE,
  ALL_PERMISSIONS,
  USERMANAGEMENT_PERMISSIONS,
  LIVESTREAM_PERMISSIONS,
  CONTENTMANAGEMENT_PERMISSIONS,
  TRAININGMATERIALS_PERMISSIONS,
  NOTIFICATION_PERMISSIONS,
  PAGES,
  PAGE_TYPES,
  INNER_NOTIFICATIONS,
  NOTIFICATION_TYPES,
  EDUCATOR_RESOURCE_TYPES,
  VIDEO_STATUS,
  RESOURCE_TYPES,
  ACADEMY_TYPES,
  SCHEDULE_TYPE,
  SCHEDULE_TYPES,
  DEFAULT_SUBSCRIPTION_TYPES,
  PUBLISH_TYPES,
  EXPIRED_TYPES,
  expiredTypeMap,
  ANALYTICS_SORT_TYPES,
  DURATION_FILTER_TYPES,
  SETTINGS_PERMISSIONS,
  TRIMMED_FILTER_TYPES,
  KNOWLEDGE_LEVEL_TYPE,
  PATH_VIDEO_UPLOAD_STATUS,
};
