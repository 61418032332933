export const QUERY_KEY = {
  CURRENT_USER: "current-user",
  CURRENT_USER_PERMISSIONS: "current-user-permissions",
  CURRENT_EDUCATOR: "current-educator",
  MAINTENANCE_SETTINGS: "maintenance-settings",
  PLATFORMS: "platforms",
  GET_ROLES: "get-roles",
  GET_ROLE: "get-role",
  GET_TAG: "get-tag",
  GET_TAGS: "get-tags",
  GET_NOTIFICATIONS: "get-notifications",
  GET_NOTIFICATION: "get-notification",
  GET_EDUCATOR_RESOURCE: "get-educator-resource",
  GET_EDUCATOR_RESOURCES: "get-educator-resources",
  GET_LANGUAGES: "get-languages",
  GET_LANGUAGE: "get-language",
  GET_USER: "get-user",
  GET_USERS: "get-users",
  GET_ADMIN_USERS: "get-admin-users",
  GET_ADMIN_ACTION_LOGS: "get-admin-action-logs",
  APP_CONTEXT_GET_ALL_EDUCATORS: "app-context-get-all-educators",
  APP_CONTEXT_GET_CATEGORIES: "app-context-get-categories",
  GET_TIMEZONES: "get-timezones",
  GET_TIMEZONE: "get-timezone",
  GET_EDUCATORS: "get-educators",
  GET_ALL_EDUCATORS: "get-all-educators",
  GET_EDUCATOR: "get-educator",
  GET_CATEGORY: "get-category",
  GET_CATEGORIES: "get-categories",
  GET_SUBCATEGORY: "get-subcategory",
  GET_SUBCATEGORIES: "get-subcategories",
  GET_SCHEDULES: "get-schedules",
  GET_SCHEDULE: "get-schedule",
  GET_SCHEDULE_CHANNELS: "get-schedule-channels",
  GET_AVAILABLE_TIME_SLOTS: "get-available-time-slots",
  GET_CHANNELS: "get-channels",
  GET_CHANNEL: "get-channel",
  GET_CHANNEL_DELAYED_JOBS: "get-channel-delayed-jobs",
  GET_CHANNEL_FAQS: "get-channel-faqs",
  GET_CHANNEL_FAQ: "get-channel-faq",
  GET_S3_VIDEO_URL: "get-s3-video-url",
  GET_VIDEO: "get-video",
  GET_VIDEO_ANALYTICS: "get-video-analytics",
  GET_PLAYLIST: "get-playlist",
  GET_ACADEMY_PLAYLIST: "get-academy-playlist",
  GET_VIDEOS: "get-videos",
  GET_PLAYLISTS: "get-playlists",
  GET_ACADEMY_PLAYLISTS: "get-academy-playlists",
  GET_AVAILABLE_TAGS_FOR_CHANNEL: "get-available-tags-for-channel",
  GET_SUBSCRIPTIONS: "get-subscriptions",
  GET_SUBSCRIPTION: "get-subscription",
  GET_S3_SIGNED_URL: "get-s3-signed-url",
  GET_S3_MULTIPART_UPLOAD_DATA: "get-s3-multipart-upload-data",
  GET_LIVESTREAM_URL: "get-livestream-url",
  GET_PATHS_LIST: "get-paths-list",
  GET_POLICY: "get-policy",
  GET_ASSETS: "get-assets",
  GET_ASSET: "get-asset",
  GET_RESOURCE: "get-resource",
  GET_RESOURCES: "get-resources",
  RECOMMENDATION_SETTINGS: "recommendations-settings",
};
